import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from '../Pagination';
import SearchBox from '../SearchBox';
import { alertService } from '../../services/alert.service';
import Confirm from '../Confirm';
import moment from 'moment';
// import userStore from '../../stores/UserStore';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { formatPhoneNumber } from '../../utils/stringUtils';

function UsersList({ roles = null }) {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [users, setUsers] = useState([]);
	const [revealedEmails, setRevealedEmails] = useState([]);
	const [revealedPhones, setRevealedPhones] = useState([]);
	const [exportConfirm, setExportConfirm] = useState(false);
	const [sortBy, setSortBy] = useState('first_name');
	const [sortOrder, setSortOrder] = useState('asc');

	const [queryRoles] = useState(roles);

	let navigate = useNavigate();

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchUsers();
		}
	}, [page, orgsStore.currentOrg]);

	useEffect(() => {
		fetchUsers();
	}, [sortBy, sortOrder]);

	const fetchUsers = async () => {
		const opts = {};
		if (sortBy) {
			opts.sortBy = sortBy;
		}
		if (sortOrder) {
			opts.sortOrder = sortOrder;
		}
		const usersResponse = await tetherApi.getUsers(page, perPage, opts);
		if (usersResponse && usersResponse.data) {
			setUsers(usersResponse.data);
			const totalCount = usersResponse.total;
			const pagesCount = Math.ceil(totalCount / perPage);

			setTotal(totalCount);
			setNumPages(pagesCount);
			setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
			setEndIndex(perPage * (page - 1) + usersResponse.data.length);
		} else {
			alertService.error('Failed to fetch users. Please try again later.');
		}
	};

	const searchUsers = async (query) => {
		if (query) {
			const usersResponse = await tetherApi.searchUsers(query, {
				roles: queryRoles,
			});

			setUsers(usersResponse.data);
		} else {
			fetchUsers();
		}
	};

	const createExport = async () => {
		const result = await tetherApi.createExport({ exportType: 'User' });

		if (result) {
			alertService.success('Export is being processed!');

			navigate('/exports');
		}
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div>
				<div className="sm:flex-auto">
					<div className="sm:flex sm:items-center justify-between">
						<div className="mr-4">
							<h1 className="text-lg font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-2 lg:pb-0">Users</h1>
						</div>
						<SearchBox
							onQueryChange={searchUsers}
							onSearchCancel={fetchUsers}
							items={users}
							itemText="Users"
						/>
						<div className="sm:mt-0 xs:ml-0 ml-4 sm:flex-none">
							<button
								type="button"
								onClick={() => {
									setExportConfirm(true);
								}}
								className={
									total === 0 || !orgsStore.currentOrg
										? 'hidden'
										: 'mr-3 inline-flex items-center justify-center rounded-lg border bg-white px-4 py-2 text-sm font-medium shadow-sm'
								}
							>
								Export
							</button>
							<Link
								to="/invitations/new"
								className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900 xs:mt-4 lg:mt-0"
							>
								New User
							</Link>
						</div>
					</div>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link to="/users/new" className="hidden">
						Add User
					</Link>
				</div>
			</div>
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{users.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No members to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No members are available.
										</p>
										<Link
											to="/users/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											New User
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'first_name') {
														setSortBy('first_name');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													First
													{sortBy === 'first_name' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'last_name') {
														setSortBy('last_name');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Last
													{sortBy === 'last_name' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'email') {
														setSortBy('email');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Email
													{sortBy === 'email' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'phone') {
														setSortBy('phone');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Phone
													{sortBy === 'phone' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'created_at') {
														setSortBy('created_at');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Joined
													{sortBy === 'created_at' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'app_opened_at') {
														setSortBy('app_opened_at');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Last Active
													{sortBy === 'app_opened_at' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Edit
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{users.map((user, userIdx) => (
											<tr key={user.id} className={userIdx % 2 === 0 ? undefined : 'bg-white'}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													{user.firstName}
												</td>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													{user.lastName}
												</td>
												<td
													onClick={() => {
														if (revealedEmails.includes(user.id)) {
															setRevealedEmails(
																revealedEmails.filter((id) => id !== user.id),
															);
														} else {
															setRevealedEmails([...revealedEmails, user.id]);
														}
													}}
													className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate cursor-pointer ${
														revealedEmails.includes(user.id) ? '' : 'blur-sm'
													}`}
												>
													{user.email}
												</td>
												<td
													onClick={() => {
														if (revealedPhones.includes(user.id)) {
															setRevealedPhones(
																revealedPhones.filter((id) => id !== user.id),
															);
														} else {
															setRevealedPhones([...revealedPhones, user.id]);
														}
													}}
													className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate cursor-pointer ${
														revealedPhones.includes(user.id) ? '' : 'blur-sm'
													}`}
												>
													{formatPhoneNumber(user.phone)}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
													{moment(user?.joinedAt).format('LL')}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
													{user?.appOpenedAt === null
														? 'Never'
														: moment(user?.appOpenedAt).format('LL')}
												</td>
												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/users/${user.id}`}
														className="font-medium text-neutral-800 hover:text-neutral-900"
													>
														Edit
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
			<Confirm
				isOpen={exportConfirm}
				title="Export Users"
				body="This will create a .csv file and email it to your email address. You will also be able to view it by clicking Account > Exports. Are you sure you want to export your user list?"
				onConfirm={createExport}
				onCancel={() => setExportConfirm(false)}
			/>
		</div>
	);
}

export default observer(UsersList);
